import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, switchMap } from "rxjs";
import { ICardResponse } from "@domain/model/interfaces/cards.interface";
import { AuthService } from "@modules/cards-viewer/services/auth/auth.service";
import { CHANNEL_HEADER } from "@utils/constants/auth/headers.constant";
import { environment } from "environments/environment";
import { MonitorService } from "../monitor/monitor.service";

@Injectable({
    providedIn: 'root'
})
export class CardsApiService {
    http: HttpClient;
    constructor(
        handler: HttpBackend,
        private authService: AuthService,
        private monitorService: MonitorService 
    ) { 
        this.http = new HttpClient(handler);
    }

    getCustomerCard(cardNumber: string, customerId: string, xChannel: string): Observable<ICardResponse> {
        // TODO: Remove mock url and delay

        return from(this.monitorService.getMonitorHeaders()).pipe(
            switchMap((headerData) => {          
                const url = `${environment.bffCards}/bff-business-cards/card`;
                const headers = this.authService.buildHeaders({[CHANNEL_HEADER]: xChannel, ...headerData})
                return this.authService.waitForHeadersAvailability(() =>
                    this.http.post<ICardResponse>(
                    `${url}`,
                    { cardNumber, customerId },
                    { headers }
                    )
                );
            })
        )        
        
    } 
}  