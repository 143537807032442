import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { CardComponent } from './card/card.component';

@NgModule({
  declarations: [
    ModalComponent,
    CardComponent
  ],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule
  ],
  exports: [
    ModalComponent,
    CardComponent
  ]
})
export class SharedComponentsModule { }
