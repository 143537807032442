import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { CardsFacade } from "@domain/application/facade/cards-facade";
import { map, Observable, Subject, takeWhile, tap, timer, takeUntil, catchError, of, finalize, } from "rxjs";
import { ConfigContextService } from "../services/config-context/config-context.service";
import { IHandlerOperationResults, TActivity } from "@domain/model/interfaces/results-operations.interface";
import { ICardModel } from "@domain/model/interfaces/cards.interface";


@Component({
  selector: "cards-viewer-container",
  templateUrl: "./container.component.html",
  styleUrls: ["./container.component.scss"],
})
export class ContainerComponent implements OnInit, OnDestroy {

  visible = false;
  countDown$!: Observable<number>;
  unsuscribe$: Subject<boolean> = new Subject<boolean>();
  customerCard$!: ReturnType<CardsFacade['getCustomerCard']>;

  @Output() handlerOperationResults = new EventEmitter<IHandlerOperationResults>();

  constructor(
    public configContext: ConfigContextService,
    private cardsFacade: CardsFacade,
  ) {
  }

  ngOnInit(): void {
    const { cardNumber, customerId, xChannel } = this.configContext.configValue!;
    this.customerCard$ = this.cardsFacade.getCustomerCard(cardNumber, customerId, xChannel)
      .pipe(
        catchError((values) => {
          this.handlerOperationResults.emit({ action: 'errorOperation' })
          return of({ ...values, statusOperation: 'failed' })
        }),
        tap((value) => {
          if (value.cardNumber?.length > 0) {
            this.setValues(value, 'initValCard')
            this.configContext.isExistesVCard = true
          }
          return this.countDown$ = timer(0, 1000).pipe(
            takeUntil(this.unsuscribe$),
            finalize(() => {
              this.setValues(value, 'finishTemp')
              value.cardNumber = '1001100110011001'
              value.securityCode = '7O7'
              if (value.securityCodeCSC) value.securityCodeCSC = '1OO1'
            }),
            map(index => (this.configContext?.configValue?.countDown ?? 60 * 10) - index), takeWhile(Boolean, true))
        }),
      )

  }


  private setValues(value: ICardModel, activity: TActivity): void {
    this.handlerOperationResults.emit({
      action: 'successOperation',
      numberAcount: value.cardNumber,
      activity
    })
  }

  handlerNumberAccount(num: string) {
    this.handlerOperationResults.emit({
      action: 'successOperation',
      numberAcount: num,
      activity: 'copie'
    })
  }

  ngOnDestroy(): void {
    this.unsuscribe$.next(true);
    this.unsuscribe$.unsubscribe();
  }
}
