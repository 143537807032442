import { Injectable } from "@angular/core";
import { CardsApiService } from "@domain/infraestructure/api/cards-api.service";
import { ICardModel, ICardResponse } from "@domain/model/interfaces/cards.interface";
import { map, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CardsFacade {
    constructor(private cardService: CardsApiService) { }

    getCustomerCard(cardNumber: string, customerId: string, xChannel: string): Observable<ICardModel> {
        return this.cardService.getCustomerCard(cardNumber, customerId, xChannel)
            .pipe(map((data: ICardResponse) => {
                //data.csc = null as any
                return {
                    cardNumber: data.cardNumber,
                    expirationDate: data.expiryDate,
                    securityCode: data.cvv, // data.cvv || data.csc,
                    securityCodeCSC: data.csc,
                    cardType: data.csc ? "AMEX" : "VISA",
                    markType: data.csc ? 'Tarjeta Digital AMEX' : 'Tarjeta Digital Visa',
                    statusOperation: 'success'
                }
            }));
    }
} 