<div class="counter">
  <div class="counter__success" *ngIf="countDown" (mouseover)="mouseOver()">
    <img [src]="'assets/icons/clock-success.svg' | locationAssets" alt="clock" >
    <h1 class="counter__success--time">{{countDown | toTime}}</h1>
    <span>min</span>
    <div class="popover" *ngIf="showPopover">
      <div class="popover__arrow"></div>
      <p class="popover__text">Tiempo de visualización de datos</p>
    </div>
  </div>
  <div class="counter__error" *ngIf="!countDown">
    <img [src]="'assets/icons/clock-error.svg' | locationAssets" alt="clock">
    <h1 class="counter__error--time">El tiempo ha terminado</h1>
  </div>
</div>
 