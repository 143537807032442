<ng-container *ngIf="typeTemplate?.model === 'MODAL'; else templateCard">
  <cv-modal>
    <ng-container modal-body *ngTemplateOutlet="body"></ng-container>
  </cv-modal>
</ng-container>

<ng-template #templateCard>
  <cv-card>
    <ng-container card-body *ngTemplateOutlet="body"></ng-container>
  </cv-card>
</ng-template>

<!-- TEMPLATES -->
<ng-template #body>
  <div class="card">
    <img
      [src]="'assets/images/check-mail.png' | locationAssets"
      alt="check-mail"
      height="172"
      width="172"
    />
    <span class="card--msm-bold"> Revisa tu correo </span>
    <span class="card--msm-sub">
      Te hemos enviado un correo con la constancia de solicitud. En los
      próximos días te contactaremos para la entrega de tu tarjeta física
    </span>
  </div>
</ng-template>
