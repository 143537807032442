import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateExpiration'
})
export class DateExpirationPipe implements PipeTransform {

  transform(value: any): string {
    // 2026/08
    // 2028/02
    const dates = value?.toString().split('/')
    const year = dates[0][2] + '' + dates[0][3]
    const month = dates[1]
    return `${month}/${year}`
  }

}
