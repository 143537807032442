<ng-template cdkPortal >
  <div class="ibk-modal">
    <div class="ibk-modal__header">
      <ng-content select="[modal-header]"></ng-content>
    </div>
    <div class="ibk-modal__subtitle">
      <ng-content select="[modal-subtitle]"></ng-content>
    </div>
    <div class="ibk-modal__counter">
      <ng-content select="[modal-countdown]"></ng-content>
    </div>
    <div class="ibk-modal__body">
      <ng-content select="[modal-body]"></ng-content>
    </div>
    <div class="ibk-modal__coupon">
      <ng-content select="[modal-coupon]"></ng-content>
    </div>
  </div>
</ng-template>
