import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthHeaders } from "@modules/cards-viewer/utils/types/auth-headers.type";
import {
  AUTHORIZATION_HEADER,
  SESSION_TOKEN_HEADER,
  SUBSCRIPTION_KEY_HEADER,
} from "@utils/constants/auth/headers.constant";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, switchMap, tap } from "rxjs";
// import {  timeout } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  http: HttpClient;
  private readonly headers$ = new BehaviorSubject<AuthHeaders | null>(null);

  constructor( handler: HttpBackend ) {
    this.http = new HttpClient(handler);

    if (environment.mockEnable) {
      this.headers$.next({
        sessionToken: '',
        subscriptionKey: '',
        authorization: '',
      })
    }
  }

  waitForHeadersAvailability<T>(
    request: (headers: AuthHeaders) => Observable<T>
  ) {
    return this.headers$.pipe(
      switchMap((headers) => request(headers!))
    );
  }

  populateHeaders({
    sessionToken,
    subscriptionKey
  }: {
    sessionToken: string;
    subscriptionKey: string
  }) {
    return this.http
      .post(`${environment.bffSecurityUrl}/v1/bff-security/oauth`, { request: { session: sessionToken } }, {
        headers: new HttpHeaders({
          "Ocp-Apim-Subscription-Key": subscriptionKey,
          "x-session-token": `SESSION=${sessionToken}`,
        }),
      })
      .pipe(
        //Tiempo de respuesta
        //timeout(30000),
        tap((res: any) => {
          this.headers$.next({
            sessionToken,
            subscriptionKey,
            authorization: `Bearer ${res.access_token}`,
          });
        })
      );
  }

  buildHeaders(headers: Record<string, string> = {}) {
    const authHeaders = this.headers$.value!;
    return {
      ...headers,
      [AUTHORIZATION_HEADER]: authHeaders.authorization,
      [SESSION_TOKEN_HEADER]: authHeaders.sessionToken,
      [SUBSCRIPTION_KEY_HEADER]: authHeaders.subscriptionKey,
      // [CHANNEL_HEADER]: 'SFI'
    };
  }
}
