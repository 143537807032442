import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import 'zone.js';
import { loadAsset, loadScript } from "@utils/helpers/asset-loader.helper";

const hostname = window.location.href;

if (hostname.includes('izipay.pe/comprar')) {
  loadScript(`${environment.cdn}/assets/js/fingerprint-no-pollyfill.js`);
} else {
  loadScript(`${environment.cdn}/assets/js/fingerprint.js`);
}

if (environment.production) {
  enableProdMode();
}

loadAsset(`${environment.cdn}/styles.css`); 

// loadScript( 
//   'https://web-dev.digital.interbank.pe/ds/v2/ibk-ds/ibk-ds.esm.js',
//   true
// );

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));

