import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IntialConfig } from '@modules/cards-viewer/utils/interfaces/initial-config.interface';

@Component({
  selector: 'error-values-card-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent {

  @Input() typeTemplate?: IntialConfig | null;
  @Output() emitRetry: EventEmitter<boolean> = new EventEmitter<boolean>()


  public handlerRetry() {
    this.emitRetry.emit(true)
  }

}
