import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardNumberFormat'
})
export class CardNumberFormatPipe implements PipeTransform {

  transform(value: string, type: 'visa' | 'amex'): string {
    switch (type) {
      case "visa":
      case "amex":
        return this.visaFormat(value);
    }
  }

  private visaFormat(cardNumber: string): string {
    return cardNumber.split('').reduce((seed, next, index) => {
      if (index !== 0 && !(index % 4)) seed += ' ';
      return seed + next;
    }, '');
  }

}
