<div class="card" [style.filter]="blur ? 'blur(3px)' : 'none'">
  <div class="card__front" *ngIf="front">
    <div class="card__front--header">
      <img class="ibk" [src]="'assets/images/ibk-icon.svg' | locationAssets" alt="ibk" />
      <h3 class="title">{{ markType }}</h3>
    </div>
    <div class="card__front--number">
      <p>{{ number }}</p>
      <div
        class="copy"
        [style.cursor]="blur ? 'default' : 'pointer'"
        (click)="copy()"
      >
        <p>{{ textCopy }}</p>
        <img [src]="'assets/icons/copy.svg' | locationAssets" alt="copy" />
      </div>
    </div>
    <div class="card__front--footer">
      <img [src]="'assets/icons/visa.svg' | locationAssets" alt="visa" />
      <p>
        Vence
        <span>{{ expiration | dateExpiration }}</span>
      </p>
    </div>
  </div>

  <div class="card__back" *ngIf="back">
    <div class="card__back--band"></div>
    <div class="card__back--data">
      <div class="band"></div>
      <div class="cvv">
        <p>
          CVV
          <span>{{ cvv }}</span>
        </p>
      </div>
    </div>
    <div class="card__back--warning">
      <p>El CVV es tu clave para hacer compras online con tu tarjeta</p>
    </div>
  </div>
</div>
