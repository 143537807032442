/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@modules/cards-viewer/services/auth/auth.service';
import { MONITOR_TRACKING_DATA, MONITOR_TRACKING_DEVICE } from '@utils/constants/auth/headers.constant';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

declare const MPFingerprint: any;

@Injectable({
  providedIn: 'root',
})
export class MonitorService {
  http: HttpClient;
  constructor(handler: HttpBackend, private readonly authService: AuthService) {
    this.http = new HttpClient(handler);
  }

  private async fetchClientIp(): Promise<any> {
    return firstValueFrom(
      this.http.get(`${environment.bffCards}/web-client-info`, {
        headers: this.authService.buildHeaders(),
      })
    );
  }

  private async getData() {
    const response = await MPFingerprint.getData(true, true);
    return response;
  }

  async getMonitorHeaders() {
    const monitorData = await this.getData();
    const IPClientInformation = await this.fetchClientIp();
    monitorData['Geoip'] = IPClientInformation;
    console.log(monitorData)

    const customHeader = `SXF${+new Date()}`;
    return Promise.resolve({
      [MONITOR_TRACKING_DEVICE]: window.btoa(JSON.stringify(monitorData)),
      [MONITOR_TRACKING_DATA]: window.btoa(
        JSON.stringify({
          ip: ''/* IPClientInformation?.query ||  "192.168.0.1" */,
          originId: 'WEB-JS',
          securityLevel: '0P',
          sessionId: customHeader,
        }),
      ),
    });
  }
}
