import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { PipesModule } from "@modules/cards-viewer/pipes/pipes.module";
import { SharedModule } from "@custom-modules/shared.module";

import { CardLessComponent } from './cards/cardless/cardless.component'; 
import { DirectivesModule } from "../directives/directives.module";
import { CounterSkeletonComponent } from './counter-skeleton/counter-skeleton.component';
import { CardSkeletonVisaComponent } from './card-skeleton/card-skeleton-visa/card-skeleton-visa.component';
import { CardSkeletonAmexComponent } from './card-skeleton/card-skeleton-amex/card-skeleton-amex.component';
import { TicketComponent } from './ticket/ticket.component';
import { SharedComponentsModule } from "app/shared/shared-components.module";


@NgModule({
  declarations: [
    CardLessComponent, 
    CounterSkeletonComponent,
    CardSkeletonVisaComponent,
    CardSkeletonAmexComponent,
    TicketComponent,
  ],
  imports: [ 
    DirectivesModule,
    PipesModule,
    SharedModule,
    SharedComponentsModule
  ],
  exports: [
    CardLessComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomComponentsModule { }
