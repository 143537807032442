import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'; 
import { ContainerComponent } from './container/container.component'; 
import { SharedModule } from '@custom-modules/shared.module';
import { SharedComponentsModule } from 'app/shared/shared-components.module';

@NgModule({
  declarations: [ 
    ContainerComponent,
  ],
  imports: [  
    SharedModule,
    SharedComponentsModule
  ],
  exports: [ContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CheckEmailModule { }
