<ng-container *ngIf="typeTemplate?.model === 'MODAL'; else templateCard">
  <cv-modal>
    <ng-container modal-body *ngTemplateOutlet="bodyError"></ng-container>
  </cv-modal>
</ng-container>

<ng-template #templateCard>
  <cv-card>
    <ng-container card-body *ngTemplateOutlet="bodyError"></ng-container>
  </cv-card>
</ng-template>

<!-- TEMPLATE -->
<ng-template #bodyError>
  <div class="card">
    <img
      [src]="'assets/images/error-values-card.png' | locationAssets"
      alt="error-values-card"
      height="172"
      width="172"
    />
    <span class="card--msm-bold"> Lo sentimos, </span>
    <span class="card--msm-sub">
      Hemos tenido un error al momento de cargar los datos de tu tarjeta
      digital, por favor inténtalo de nuevo.
    </span>
    <button class="card--btn-retry" (click)="handlerRetry()">
      Volver a cargar
    </button>
  </div>
</ng-template>
