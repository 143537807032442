export const environment = {
  production: false,
  mockEnable: false,
  bffCards: 'https://apiqa.digital.interbank.pe/business-cards/v1',
  bffSecurityUrl: 'https://apiqa.digital.interbank.pe/bff-security',
  // TODO: Change cdn and iframe later
  cdn: 'https://web-qa.digital.interbank.pe/bindings/js/cards-viewer@0.0.1',
  // cdn: 'http://localhost:4300',
  iframeUrl: '__VALUE_TO_REPLACE__'
};
