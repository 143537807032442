<div class="ibk-pl" *ngIf="!(isLoading$ | async)">
  <cards-viewer-container
    *ngIf="resultsApiCards === 'isAuthDone'"
    (handlerOperationResults)="changeOperationResults($event)"
  >
  </cards-viewer-container>
  <error-values-card-container
    *ngIf="resultsApiCards === 'authError'"
    (emitRetry)="handlerRetry()"
    [typeTemplate]="configContext.config$ | async"
  >
  </error-values-card-container>
  <check-email-container
    *ngIf="resultsApiCards === 'emailcheck'"
    [typeTemplate]="configContext.config$ | async"
  ></check-email-container>
</div>

<div class="ibk-pl" *ngIf="isLoading$ | async">
  <spinner-container 
  [typeTemplate]="configContext.config$ | async"
  ></spinner-container>
</div>
