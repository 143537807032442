import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

const modules = [BrowserAnimationsModule, BrowserModule, HttpClientModule, CommonModule];

@NgModule({
  imports: [...modules],
  exports: [...modules],
})
export class CoreModule { }
