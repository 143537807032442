import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

@Pipe({
  name: 'locationAssets'
})
export class LocationAssetsPipe implements PipeTransform {

  transform(value: string): string {

    const path = environment.cdn // Direccion en donde estan los assets

    const asset = value + ''.toString()

    return `${path}/${asset}`;
  }
}
