import { NgModule } from '@angular/core';
import { SharedModule } from '@custom-modules/shared.module';
import { DIRECTIVES } from '.';

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [SharedModule],
  exports: [...DIRECTIVES],
})
export class DirectivesModule {}
