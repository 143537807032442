import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, Renderer2, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ICardModel } from '@domain/model/interfaces/cards.interface';
import { ConfigContextService } from '@modules/cards-viewer/services/config-context/config-context.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cv-cardless',
  templateUrl: './cardless.component.html',
  styleUrls: ['./cardless.component.scss']
})
export class CardLessComponent implements OnDestroy {

  @ViewChild("subTitleId", { static: false }) subTitleId: ElementRef<HTMLElement> = {} as ElementRef<HTMLElement>;

  @Input() countDown: number | null = 0;
  @Input() title!: string;
  @Input() subTitle!: string;
  @Input() coupon!: string;
  @Input() customerCard!: ICardModel | null;
  @Input() typeTemplate!: string;

  @Output() handlerNumberAccount = new EventEmitter<string>()

  blur = false;
  isLoading = true;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private readonly existeValuesCardService: ConfigContextService
  ) {
    this.existeValuesCardService.isExistesVCard$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        if (res) this.setFontWeight()
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete();
  }

  private setFontWeight() {
    const setStyle = (span: Element) => {
      this.renderer.setStyle(span, 'font-weight', '900')
      this.renderer.setStyle(span, 'font-family', 'Geometria-Medium')
      this.cd.detectChanges()
    }
    setTimeout(() => {
      if (this.subTitle) {
        for (let index = 0; index < 10; index++) {
          const span = this.subTitleId?.nativeElement.children[index]
          if (span) setStyle(span)
        }
      }
    }, 10);
  }

  timeIsUp(): void {
    this.blur = true;
  }

  handlerNumAccount(num: string) {
    this.handlerNumberAccount.emit(num);
  }

}
