import { AfterViewInit, Component, EventEmitter, Output, ViewChild, } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';

@Component({
  selector: 'cv-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit {

  @ViewChild(CdkPortal) public readonly portal: CdkPortal | undefined;
  @Output() public readonly closeDialog = new EventEmitter<void>();

  private readonly overlayConfig = new OverlayConfig({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
    scrollStrategy: this.overlay.scrollStrategies.block(),
    panelClass: 'ibk-cv-modal',
    backdropClass: 'ibk-cv-backdrop'
  })
  private overlayRef = this.overlay.create(this.overlayConfig);

  constructor(
    private readonly overlay: Overlay,
  ) {
    this.overlayRef.backdropClick().subscribe(() => {
      this.closeDialog.emit();
    });
  }

  public ngAfterViewInit(): void {
    this.overlayRef?.attach(this.portal);
    const zIndex = document.getElementsByClassName('cdk-overlay-container') as any
    if (zIndex[0]) zIndex[0].style.zIndex = "9999";
  }

  public ngOnDestroy(): void {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();
  }

}
