import { Injectable } from '@angular/core';
import { IntialConfig } from '@modules/cards-viewer/utils/interfaces/initial-config.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigContextService {
  private readonly _config = new BehaviorSubject<IntialConfig>({} as IntialConfig);
  private readonly _isExistsValuesCard = new BehaviorSubject<boolean>(false);


  get configValue() {
    return this._config.value;
  }

  get config$(): Observable<IntialConfig | null> {
    return this._config.asObservable();
  }

  set config(config: IntialConfig) {
    this._config.next(config);
  }

  get isExistesVCardValue(): boolean {
    return this._isExistsValuesCard.value;
  }

  get isExistesVCard$(): Observable<boolean> {
    return this._isExistsValuesCard.asObservable();
  }

  set isExistesVCard(is: boolean) {
    this._isExistsValuesCard.next(is);
  }
}
