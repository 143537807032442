import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LocationAssetsPipe } from './pipes/location-assets.pipe';

const modules = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule];

@NgModule({
  declarations: [LocationAssetsPipe],
  imports: [...modules],
  exports: [
    ...modules,
    LocationAssetsPipe,
  ],
})
export class SharedModule { }
