import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '@custom-modules/shared.module';
import { CORE_COMPONENTS } from '.';

/**
 * Core Module
 */
@NgModule({
  declarations: [...CORE_COMPONENTS],
  imports: [SharedModule],
  exports: [...CORE_COMPONENTS],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule { }
