<ng-container *ngIf="typeTemplate === 'MODAL'; else templateCard">
  <cv-modal  *ngIf="customerCard; else loadingIndicator">    
    <ng-container modal-header *ngTemplateOutlet="header"></ng-container>    
    <ng-container modal-subtitle *ngTemplateOutlet="subtitle"></ng-container>    
    <ng-container modal-countdown *ngTemplateOutlet="countdown"></ng-container>
    <ng-container modal-body *ngTemplateOutlet="body"></ng-container>
    <ng-container modal-coupon *ngTemplateOutlet="couponTemp"></ng-container>
  </cv-modal>
</ng-container>

<ng-template #templateCard>
  <cv-card  *ngIf="customerCard; else loadingIndicator">
    <ng-container card-header *ngTemplateOutlet="header"></ng-container>    
    <ng-container card-subtitle *ngTemplateOutlet="subtitle"></ng-container>    
    <ng-container card-countdown *ngTemplateOutlet="countdown"></ng-container>
    <ng-container card-body *ngTemplateOutlet="body"></ng-container>
    <ng-container card-coupon *ngTemplateOutlet="couponTemp"></ng-container>
  </cv-card>
</ng-template>

<!-- TEMPLATE -->

<ng-template #header>
  <h1 class="title">{{ title }}</h1>
</ng-template>

<ng-template #subtitle>
  <ng-container *ngIf="subTitle">
    <h4 class="subtitle" [innerHTML]="subTitle" #subTitleId></h4>  
  </ng-container>
</ng-template>

<ng-template #countdown>
  <div class="counter">
    <p class="counter__text">Los datos de tu tarjeta expiran en:</p>
    <cv-counter-skeleton [countDown]="countDown" (timeIsUp)="timeIsUp()">
    </cv-counter-skeleton>
  </div>
</ng-template>

<ng-template #body>
  <div class="card" *ngIf="customerCard && customerCard.cardType === 'AMEX'">
    <cv-card-skeleton-amex
      [front]="true"
      [markType]="customerCard.markType"
      [number]="customerCard.cardNumber | cardNumberFormat : 'amex'"
      [expiration]="customerCard.expirationDate"
      [blur]="blur || !customerCard"
      [csc]="customerCard.securityCodeCSC"
      (handlerNumberAccount)="handlerNumAccount($event)"
    >
    </cv-card-skeleton-amex>
    <cv-card-skeleton-amex
      [back]="true"
      [cvv]="customerCard.securityCode"
      [number]="customerCard.cardNumber | cardNumberFormat : 'amex'"
      [blur]="blur || !customerCard"
    >
    </cv-card-skeleton-amex>
  </div>

  <div class="card" *ngIf="customerCard && customerCard.cardType === 'VISA'">
    <cv-card-skeleton-visa
      [front]="true"
      [markType]="customerCard.markType"
      [number]="customerCard.cardNumber | cardNumberFormat : 'visa'"
      [expiration]="customerCard.expirationDate"
      [blur]="blur || !customerCard"
      (handlerNumberAccount)="handlerNumAccount($event)"
    >
    </cv-card-skeleton-visa>
    <cv-card-skeleton-visa
      [back]="true"
      [cvv]="customerCard.securityCode"
      [number]="customerCard.cardNumber | cardNumberFormat : 'visa'"
      [blur]="blur || !customerCard"
    >
    </cv-card-skeleton-visa>
  </div>
</ng-template>

<ng-template #couponTemp>
  <div class="coupon" *ngIf="coupon && !blur">
    <cv-ticket>
      <p class="coupon__instruction">Usa tu cupón de descuento:</p>
      <p class="coupon__code">{{ coupon }}</p>
    </cv-ticket>
  </div>
</ng-template>

<!-- TEMPLATE FIJO -->
<ng-template #loadingIndicator>
  <ng-container *ngIf="typeTemplate === 'MODAL'; else templateCard">
    <cv-modal>
      <ng-container modal-body *ngTemplateOutlet="bodyLoad"></ng-container>
    </cv-modal>
  </ng-container>

  <ng-template #templateCard>
    <cv-card>
      <ng-container card-body *ngTemplateOutlet="bodyLoad"></ng-container>
    </cv-card>
  </ng-template>

  <ng-template #bodyLoad>
    <div class="a-loader js-loader">
      <div class="a-loader__spinner">
        <div class="a-loader__logo"></div>
      </div>
    </div>
  </ng-template>
</ng-template>

