import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "@custom-modules/shared.module";
import { COMPONENTS } from ".";

import { CoreModule } from "./core/core.module";
import { DirectivesModule } from "./directives/directives.module";
import { CustomComponentsModule } from "./components/custom-components.module";

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    SharedModule,
    CoreModule,
    DirectivesModule,
    CustomComponentsModule
  ],
  exports: [...COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CardsViewerModule { }
