import { Component } from '@angular/core';

@Component({
  selector: 'cv-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent{

}
