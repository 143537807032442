    <div class="ibk-card">
      <div class="ibk-card__header">
        <ng-content select="[card-header]"></ng-content>
      </div>
      <div class="ibk-card__subtitle">
        <ng-content select="[card-subtitle]"></ng-content>
      </div>
      <div class="ibk-card__counter">
        <ng-content select="[card-countdown]"></ng-content>
      </div>
      <div class="ibk-card__body">
        <ng-content select="[card-body]"></ng-content>
      </div>
      <div class="ibk-card__coupon">
        <ng-content select="[card-coupon]"></ng-content>
      </div>
    </div>
