<!-- <ng-container *ngIf="{config: configContext.config$ | async, customerCard: customerCard$ | async} as initialData"> -->
  <ng-container *ngIf="{config: configContext.config$ | async} as initialData">
  <ng-container *ngIf="initialData.config">  
    <cv-cardless 
        [countDown]="countDown$ | async"
        [title]="initialData.config!.title"
        [subTitle]="initialData.config!.subTitle"
        [coupon]="initialData.config!.coupon!"
        [typeTemplate]="initialData.config!.model"
        [customerCard]="customerCard$ | async"
        (handlerNumberAccount)="handlerNumberAccount($event)">
      </cv-cardless>
  </ng-container>
</ng-container>
