import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cv-card-skeleton-visa',
  templateUrl: './card-skeleton-visa.component.html',
  styleUrls: ['./card-skeleton-visa.component.scss']
})
export class CardSkeletonVisaComponent {

  @Input() front = false;
  @Input() number?: string;
  @Input() expiration?: string;
  @Input() markType?: string;

  @Input() back = false;
  @Input() cvv?: string;

  @Input() blur = false;

  textCopy = 'Copiar';


  @Output() handlerNumberAccount = new EventEmitter<string>()

  copy() {
    if (!this.blur) {
      const numAccount = (this.number as string).replace(/\s+/g, '')
      this.clipboardWrite(numAccount)
      this.handlerNumberAccount.emit(numAccount)
      this.textCopy = '¡Copiado!';
      setTimeout(() => {
        this.textCopy = 'Copiar';
      }, 1000);
    }
  }

  async clipboardWrite(numAccount: string) {
    await navigator.clipboard.writeText(numAccount);

  }

}
