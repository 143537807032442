import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cv-card-skeleton-amex',
  templateUrl: './card-skeleton-amex.component.html',
  styleUrls: ['./card-skeleton-amex.component.scss']
})
export class CardSkeletonAmexComponent {

  @Input() front = false;
  @Input() number?: string;
  @Input() expiration?: string;
  @Input() markType?: string;

  @Input() back = false;
  @Input() cvv?: string;
  @Input() csc?: string;

  @Input() blur = false;

  @Output() handlerNumberAccount = new EventEmitter<string>()

  textCopy = 'Copiar'; 

  copy() {
    if (!this.blur) {
      const numAccount = (this.number as string).replace(/\s+/g, '');
      this.handlerNumberAccount.emit(numAccount);
      this.clipboardWrite(numAccount)
      this.textCopy = '¡Copiado!';
      setTimeout(() => {
        this.textCopy = 'Copiar';
      }, 1000);
    }
  }

  private async clipboardWrite(numAccount: string) {
    await navigator.clipboard.writeText(numAccount);
  }

}
