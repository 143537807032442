import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'cv-counter-skeleton',
  templateUrl: './counter-skeleton.component.html',
  styleUrls: ['./counter-skeleton.component.scss']
})
export class CounterSkeletonComponent implements OnChanges {

  @Input() countDown: number | null = 0;

  @Output() timeIsUp: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  showPopover = false;

  ngOnChanges(): void {
    if (this.countDown === 0) this.timeIsUp.emit(true);
  }

  mouseOver(): void {
    this.showPopover = true;
    setTimeout(() => {
      this.showPopover = false;
    }, 1500)
  }
}
