import { Component, ElementRef, EventEmitter, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { IHandlerOperationResults } from '@domain/model/interfaces/results-operations.interface';
import { AuthService } from '@modules/cards-viewer/services/auth/auth.service';
import { ConfigContextService } from '@modules/cards-viewer/services/config-context/config-context.service';
import { IntialConfig } from '@modules/cards-viewer/utils/interfaces/initial-config.interface';
import { IResultApiCard } from '@utils/interfaces/config.interface';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  private count = 0;

  /** Cantidad limitante de intentos para obtener los valores de la tarjeta*/
  private qtyRetryPost = 3;

  public resultsApiCards: IResultApiCard = 'none'
  public isLoading$ = new BehaviorSubject<boolean>(true)

  private sensibleAttributes: string[] = [];

  @Output() handlerOperationResults = new EventEmitter<IHandlerOperationResults>();

  private valuesOperation$ = new BehaviorSubject<IHandlerOperationResults>({} as IHandlerOperationResults);


  constructor(
    public readonly configContext: ConfigContextService,
    private readonly host: ElementRef<HTMLElement>,
    private readonly authService: AuthService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.init()
  }

  private async init(readAttribute = true) {

    this.isLoading$.next(true)
    this.resultsApiCards = 'none'

    const element = this.host.nativeElement;

    let config: IntialConfig = this.configContext.configValue as IntialConfig;

    if (readAttribute) {
      config = {
        sessionToken: this.readAttribute(element, 'session-token', true, true),
        subscriptionKey: this.readAttribute(element, 'subscription-key', true, true),
        cardNumber: this.readAttribute(element, 'card-number', true, true),
        customerId: this.readAttribute(element, 'customer-id', true, true),
        countDown: Number(this.readAttribute(element, 'count-down', false)) || 1 * 10,
        coupon: this.readAttribute(element, 'coupon', false),
        title: this.readAttribute(element, 'title', false),
        subTitle: this.readAttribute(element, 'sub-title', false),
        xChannel: this.readAttribute(element, 'x-channel', false) || 'PEP',
        model: this.readAttribute(element, 'model', false) || 'MODAL',
      }; 

      // Eliminar datos sensibles del componente
      this.purgeHtmlAttributes();

      this.configContext.config = config;

    }

    this.authService
      .populateHeaders({
        sessionToken: config.sessionToken,
        subscriptionKey: config.subscriptionKey,
      })
      .subscribe({
        next: () => {
          this.resultsApiCards = 'isAuthDone'
          this.valuesOperation$.next({ action: 'successOperation' });
          this.isLoading$.next(false)
          this.cdr.detectChanges()
        },
        error: () => {
          this.resultsApiCards = 'authError'
          this.count++
          this.valuesOperation$.next({ action: 'errorOperation' })
          if (this.count === this.qtyRetryPost) {
            this.resultsApiCards = 'emailcheck'
            this.handlerOperationResults.emit(this.valuesOperation$.value)
          }
          this.isLoading$.next(false)
          this.cdr.detectChanges()
        }
      });
  }


  changeOperationResults(values: IHandlerOperationResults) {

    this.valuesOperation$.next(values);

    if (values.action === 'errorOperation') {
      this.resultsApiCards = 'authError'
      this.count++
    }

    if (this.count === this.qtyRetryPost) {
      this.resultsApiCards = 'emailcheck'
      this.valuesOperation$.next({ action: 'errorOperation' })
      this.handlerOperationResults.emit(this.valuesOperation$.value)
    }

    if ((values?.numberAcount || "").length > 0) {
      this.handlerOperationResults.emit(this.valuesOperation$.value)
    }
  }

  private readAttribute(elem: HTMLElement, attrName: string, isRequired = true, isSensible = false) {
    const attribute = elem.getAttribute(attrName);
    if (isSensible) this.sensibleAttributes.push(attrName);
    if (isRequired && attribute === null) throw new Error(`Attribute ${attrName} is required`);
    return attribute!;
  }

  private purgeHtmlAttributes() {
    this.sensibleAttributes.forEach((attrName) => this.host.nativeElement.removeAttribute(attrName));
  }

  public handlerRetry() {
    this.init(false)
  }
}
