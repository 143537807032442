import { Component, Input } from '@angular/core';
import { IntialConfig } from '@modules/cards-viewer/utils/interfaces/initial-config.interface';

@Component({
  selector: 'spinner-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent {
  @Input() typeTemplate?: IntialConfig | null;
 }
