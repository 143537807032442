import { Injector, NgModule } from '@angular/core';
import { CoreModule } from '@custom-modules/core.module';
import { CardsViewerModule } from '@modules/cards-viewer/cards-viewer.module';
import { createCustomElement } from '@angular/elements';
import { AppComponent } from './app.component';
import { ErrorValuesCardModule } from '@modules/error-values-card/error-values-card.module';
import { CheckEmailModule } from '@modules/check-email/check-email.module';
import { SpinnerModule } from '@modules/spinner/spinner.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    CardsViewerModule,
    ErrorValuesCardModule,
    CheckEmailModule,
    SpinnerModule
  ],
  schemas: []
})
export class AppModule {
  constructor(private readonly injector: Injector) { }

  ngDoBootstrap() {
    const appElement = createCustomElement(AppComponent, {
      injector: this.injector,
    });

    customElements.define('ibk-cards-viewer', appElement);
  }
}
