<ng-container *ngIf="typeTemplate?.model === 'MODAL'; else templateCard">
  <cv-modal>
    <ng-container modal-body *ngTemplateOutlet="bodyError"></ng-container>
  </cv-modal>
</ng-container>

<ng-template #templateCard>
  <cv-card>
    <ng-container card-body *ngTemplateOutlet="bodyError"></ng-container>
  </cv-card>
</ng-template>

<!-- TEMPLATE -->
  <ng-template #bodyError>
    <div class="a-loader js-loader">
      <div class="a-loader__spinner">
        <div class="a-loader__logo"></div>
      </div>
    </div>
  </ng-template>
