import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerComponent } from './container/container.component';
import { SharedComponentsModule } from 'app/shared/shared-components.module'; 

@NgModule({
  declarations: [
    ContainerComponent, 
  ],
  imports: [
    CommonModule, 
    SharedComponentsModule
  ],
  exports: [ContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SpinnerModule { }
